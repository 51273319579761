import React from 'react';
import WelcomeScreen from './WelcomeScreenEN';
import PresentationScreen from './PresentationScreenEN';
import Navbar from './NavBarEN';
import Tarif from './TarifScreenEN';
import Footer from './FooterEN';
import Avis from './AvisScreenEN';
import GalleryScreen from './GalleryScreenEN';

function HomeEN() {
    return (
        <div>
            <Navbar />
            <div id="welcome">
                <WelcomeScreen />
            </div>
            <div id="description">
                <PresentationScreen />
            </div>
            <div id="gallery">
                <GalleryScreen />
            </div>
            <div id="tarif">
                <Tarif />
            </div>
            <div id="avis">
                <Avis />
            </div>
            <div id="footer">
                <Footer />
            </div>

        </div>
    )
}

export default HomeEN;