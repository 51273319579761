import React from 'react'
import { Container, Row, Carousel } from 'react-bootstrap'
import '../styles/Videos.css'
import images from '../images/imageFirstFloor'
import Button from '../components/Button'
import NavBar from '../components/NavBar'
import ReactPlayer from "react-player"
import Footer from './Footer'



function Videos() {
    return (

        <Container fluid className='gallery-container'>
            <NavBar />
            <Row className='gallery-title'>
                <h1 className='title'> Vidéos</h1>
            </Row>

            <br /><br />
            <p id='video_text'>Si les photos ne vous ont toujours pas convaincus, n'hésitez pas à vous promener dans la maison avec les vidéos suivantes : </p>
            <br /><br /><br />

            <Row className='video_page'>
                <h1 className='subtitle'> Les Alentours</h1>
                <p id='title_video'>Venez survoler Plougasnou !</p>
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=QjOSSPBV6_8&ab_channel=SensationBretagne"
                    id='video'
                />

                <p id='title_video'>Plongez dans l'ambiance de la baie de morlaix !</p>
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=gpJ1K2VdVno&ab_channel=DESTINATIONBAIEDEMORLAIXBRETAGNE"
                    id='video2'
                />
                <p id='title_video'>*******</p>
            </Row>

            <Row className='video_page2'>
                <h1 className='subtitle'> Jardin</h1>
                <ReactPlayer
                    url="hhttps://www.youtube.com/watch?v=r-y2630RaV8&ab_channel=LaTerrassedeSophie"
                    id='video'
                />

                <p id='title_video'>*******</p>
            </Row>





            <Row className='video_page'>
                <h1 className='subtitle'> La Maison</h1>
                <p id='title_video'>Rez de Chaussée</p>
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=KBtcPXEEKz4&ab_channel=LaTerrassedeSophie"
                    id='video'
                />

                <br />

                <p id='title_video'>1er Etage</p>
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=SWqEVMi67ek&t=14s&ab_channel=LaTerrassedeSophie"
                    id='video2'
                />

                <p id='title_video'>2ème étage</p>
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=MQjg9B3mmEs&ab_channel=LaTerrassedeSophie"
                    id='video'
                />

                <br />
                <p id='title_video'>*******</p>
            </Row>






            <Footer />
        </Container>
    )
}
export default Videos;

