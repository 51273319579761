import React from 'react';
import '../styles/TarifStyle.css';
import tarifEte from '../images/tarif_images/ete.png';
import tarifAutomne from '../images/tarif_images/automne.png';
import tarifPrintemps from '../images/tarif_images/printemps.png';
import caution from '../images/tarif_images/caution.png';
import services from '../images/tarif_images/services_payants.png';
import { faBold } from '@fortawesome/free-solid-svg-icons';



function TarifScreen() {
    return (
        <>
            <h1 className='title'>Tarif</h1>
            <div className='tarif_container'>
                <img src={tarifAutomne} alt='house' className='tarif_saison'></img>
                <img src={tarifPrintemps} alt='house' className='tarif_saison'></img>
                <img src={tarifEte} alt='house' className='tarif_saison'></img>
            </div>
            <div className='text_container'>
                <p id='texte_tarif'>Semaine* : Du samedi au samedi - Arrivée à partir de 16h départ à 10h</p>
                <p id='texte_tarif'>Weekend** : Du vendredi au dimanche - Arrivée à partir de 16h départ à 10h</p>
                <p id='texte_tarif'>Weekend férié*** : Toussaint -  11 nov – Weekend Pâques – 1er mai – 8 mai – Ascension – Pentecôte
                   <br /> Le tarif indiqué est pour 2 nuits – Supplément de 250€ par nuit supplémentaire</p>
            </div>
            <div className='tarif_container'>
                <img src={caution} alt='house' className='service1'></img>
                <img src={services} alt='house' className='service2'></img>
            </div>



        </>
    )
}

export default TarifScreen;
