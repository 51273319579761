import React from 'react'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home';
import GalleryJardin from './pages/GalleryJardin'
import HomeEN from './pagesEN/HomeEN';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import GalleryRDC from './pages/GalleryRDC';
import GalleryFirstFloor from './pages/GalleryFirstFloor';
import GallerySecondFloor from './pages/GallerySecondFloor';
import GalleryAlentours from './pages/GalleryAlentours';
import GalleryRDCEN from './pagesEN/GalleryRDCEN';
import GalleryFirstFloorEN from './pagesEN/GalleryFirstFloorEN';
import GallerySecondFloorEN from './pagesEN/GallerySecondFloorEN';
import GalleryAlentoursEN from './pagesEN/GalleryAlentoursEN';
import GalleryJardinEN from './pagesEN/GalleryJardinEN';
import Videos from './pages/Videos';
import VideosEN from './pagesEN/VideosEN';

function App() {
  return (

    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/english" component={HomeEN} />
          <Route path="/RDC" component={GalleryRDC} />
          <Route path="/firstFloor" component={GalleryFirstFloor} />
          <Route path="/secondFloor" component={GallerySecondFloor} />
          <Route path="/jardin" component={GalleryJardin} />
          <Route path="/environment" component={GalleryAlentours} />
          <Route path="/videos" component={Videos} />
          <Route path="/GroundFloor" component={GalleryRDCEN} />
          <Route path="/firstFloorEN" component={GalleryFirstFloorEN} />
          <Route path="/secondFloorEN" component={GallerySecondFloorEN} />
          <Route path="/garden" component={GalleryJardinEN} />
          <Route path="/surroundings" component={GalleryAlentoursEN} />
          <Route path="/videosEN" component={VideosEN} />
        </Switch>
      </div>
    </Router >
  );
}


export default App;
