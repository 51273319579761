import React from 'react';
import '../styles/TarifStyle.css';
import tarifEte from '../images/tarif_images/summer.png';
import tarifAutomne from '../images/tarif_images/autumn.png';
import tarifPrintemps from '../images/tarif_images/spring.png';
import caution from '../images/tarif_images/fees.png';
import services from '../images/tarif_images/paid_services.png';



function TarifScreen() {
    return (
        <>
            <h1 className='title'>Tariff</h1>
            <div className='tarif_container'>
                <img src={tarifAutomne} alt='house' className='tarif_saison'></img>
                <img src={tarifPrintemps} alt='house' className='tarif_saison'></img>
                <img src={tarifEte} alt='house' className='tarif_saison'></img>
            </div>
            <div className='text_container'>
                <p id='texte_tarif'>Week* : Saturday to Saturday - Arrival from 4pm departure at 10am</p>
                <p id='texte_tarif'>Weekend** : From Friday to Sunday - Arrival from 4 pm departure at 10 am</p>
                <p id='texte_tarif'>Holiday weekend*** : All Saints' Day - 11 Nov - Easter weekend - 1 May - 8 May - Ascension Day - Whitsun
                   <br /> The indicated rate is for 2 nights - Extra charge of 250€ per extra night </p>
            </div>
            <div className='tarif_container'>
                <img src={caution} alt='house' className='service1'></img>
                <img src={services} alt='house' className='service2'></img>
            </div>



        </>
    )
}

export default TarifScreen;
