import React from 'react'
import { Container, Row, Carousel } from 'react-bootstrap'
import '../styles/GalleryStyle.css'
import Button from '../components/Button'
import img1 from '../images/welcome_images/HDPicture/4.jpeg'
import img2 from '../images/welcome_images/HDPicture/5.jpeg'
import img3 from '../images/welcome_images/HDPicture/6.jpeg'
import img4 from '../images/welcome_images/HDPicture/7.jpeg'
import img5 from '../images/welcome_images/HDPicture/9.jpeg'
import img6 from '../images/welcome_images/HDPicture/10.jpeg'
import img7 from '../images/welcome_images/HDPicture/11.jpeg'
import img8 from '../images/welcome_images/HDPicture/12.jpeg'
import img9 from '../images/welcome_images/HDPicture/13.jpeg'
import img10 from '../images/welcome_images/HDPicture/14.jpeg'
import img11 from '../images/welcome_images/HDPicture/15.jpeg'
import img12 from '../images/welcome_images/HDPicture/16.jpeg'
import img13 from '../images/welcome_images/HDPicture/17.jpeg'
import img14 from '../images/welcome_images/HDPicture/18.jpeg'
import img15 from '../images/welcome_images/HDPicture/19.jpeg'


function GalleryScreen() {
    return (
        <Container fluid className='gallery-container'>
            <Row className='gallery-title'>
                <h1 className='title'> Galerie</h1>
            </Row>

            <Row className='container-carousel'>
                <Carousel className='carousel' slide={true} touch={true} fade={true} >
                    <Carousel.Item className='item'><img className='img-fluid image1' src={img1} alt='picture' /></Carousel.Item>
                    <Carousel.Item className='item'><img className='img-fluid image1' src={img2} alt='picture' /></Carousel.Item>
                    <Carousel.Item className='item'><img className='img-fluid image1' src={img3} alt='picture' /></Carousel.Item>
                    <Carousel.Item className='item'><img className='img-fluid image1' src={img4} alt='picture' /></Carousel.Item>
                    <Carousel.Item className='item'><img className='img-fluid image1' src={img5} alt='picture' /></Carousel.Item>
                    <Carousel.Item className='item'><img className='img-fluid image1' src={img6} alt='picture' /></Carousel.Item>
                    <Carousel.Item className='item'><img className='img-fluid image1' src={img7} alt='picture' /></Carousel.Item>
                    <Carousel.Item className='item'><img className='img-fluid image1' src={img8} alt='picture' /></Carousel.Item>
                    <Carousel.Item className='item'><img className='img-fluid image1' src={img9} alt='picture' /></Carousel.Item>
                    <Carousel.Item className='item'><img className='img-fluid image1' src={img10} alt='picture' /></Carousel.Item>
                    <Carousel.Item className='item'><img className='img-fluid image1' src={img11} alt='picture' /></Carousel.Item>
                    <Carousel.Item className='item'><img className='img-fluid image1' src={img12} alt='picture' /></Carousel.Item>
                    <Carousel.Item className='item'><img className='img-fluid image1' src={img13} alt='picture' /></Carousel.Item>
                    <Carousel.Item className='item'><img className='img-fluid image1' src={img14} alt='picture' /></Carousel.Item>
                    <Carousel.Item className='item'><img className='img-fluid image1' src={img15} alt='picture' /></Carousel.Item>

                </Carousel>
            </Row>

            <h4> Other photos</h4>


            <Row className='third-row'>
                <Button className="interieur RDC" link="/GroundFloor" name='The House' />
                <Button className="exterieur jardin" link="/garden" name='Garden' />
                <Button className="environnement entourage" link="/surroundings" name='The surroundings' />
            </Row>
        </Container>
    )
}
export default GalleryScreen;
