import React from 'react'
import { Container, Row, Carousel } from 'react-bootstrap'
import '../styles/GalleryStyle.css'
import images from '../images/imageFirstFloor'
import Button from '../components/Button'
import NavBar from './NavBarEN'
import ReactPlayer from "react-player"
import Footer from './FooterEN'



function GalleryFirstFloorEN() {


    const list_images = images.map((el) => {
        return (
            <Carousel.Item className='item'><img className='img-fluid image1' src={el.src} srcSet={el.srcset} alt='picture' /></Carousel.Item>
        )
    })
    return (

        <Container fluid className='gallery-container'>
            <NavBar />
            <Row className='gallery-title'>
                <h1 className='title'> House - First Floor</h1>
            </Row>

            <Row className='container-carousel'>
                <Carousel className='carousel' slide={true} touch={true} fade={true} >
                    {list_images}
                </Carousel>
            </Row>
            <br />
            <h4>Other photos</h4>



            <Row className='third-row'>
                <Button className="interieur RDC" link="/GroundFloor" name='Ground Floor' />
                <Button className="exterieur secondfloor" link="/secondFloorEN" name='Second Floor' />
                <Button className="environnement jardin" link="/garden" name='Garden' />

            </Row>


            <Footer />
        </Container>
    )
}
export default GalleryFirstFloorEN;