import React from 'react';
import { Carousel } from 'react-bootstrap';
import '../styles/WelcomeStyle.css';
import { useMediaQuery } from 'react-responsive'
import img1 from '../images/welcome_images/HDPicture/1.jpeg';
import img2 from '../images/welcome_images/HDPicture/2.jpeg';
import img3 from '../images/welcome_images/HDPicture/3.jpeg';
import img4 from '../images/welcome_images/HDPicture/4.jpeg';
import portrait1 from '../images/welcome_images/HDPicture/portrait1.jpg'
import portrait2 from '../images/welcome_images/HDPicture/portrait2.jpeg'
import portrait3 from '../images/welcome_images/HDPicture/portrait3.jpg'
import portrait4 from '../images/welcome_images/HDPicture/portrait4.jpg'


function WelcomeScreenEN() {

  const isMobileDevice = useMediaQuery({ maxWidth: 1000 })

  console.log(isMobileDevice)
  return (

    <Carousel className='carousel-container'>
      <Carousel.Item className='carousel-item'>
        {isMobileDevice ? <img
          className="image-carousel"
          src={portrait1}
          alt="First slide" /> : <img
          className="image-carousel"
          src={img1}
          alt="First slide" />}

        <Carousel.Caption className='carousel-caption' >
          <h1>Welcome to Sophie's Terrace!</h1><br />
          <h3>This superb house located in North Finistère with an incredible sea view, will not disappoint you!</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        {isMobileDevice ? <img
          className="image-carousel"
          src={portrait2}
          alt="First slide" /> : <img
          className="image-carousel"
          src={img2}
          alt="First slide" />}

        <Carousel.Caption className='carousel-caption'>
          <h3>This authentic house with 5 bedrooms for 10 people will seduce you with its charm, its comfort and its breathtaking view of the sea. You will live to the rhythm of the tides and enjoy the absolute calm.</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        {isMobileDevice ? <img
          className="image-carousel"
          src={portrait3}
          alt="First slide" /> : <img
          className="image-carousel"
          src={img3}
          alt="First slide" />}
        <Carousel.Caption className='carousel-caption'>
          <h3>Whether it's for a weekend or for several weeks, for a family reunion or a get-together with friends, you'll be able to enjoy these good moments in the peace and quiet, facing the sea...</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        {isMobileDevice ? <img
          className="image-carousel"
          src={portrait4}
          alt="First slide" /> : <img
          className="image-carousel"
          src={img4}
          alt="First slide" />}

        <Carousel.Caption className='carousel-caption'>
          <h3>What are you waiting for to come and visit us in Plougasnou? <br /><br /> We will be delighted to welcome you and show you the wonders of Brittany!</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

  )
}

export default WelcomeScreenEN;