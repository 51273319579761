import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import '../styles/PresentationStyle.css'
import { GiFamilyHouse } from 'react-icons/gi'
import { FaRestroom } from 'react-icons/fa'
import { AiOutlineWifi } from 'react-icons/ai'
import img2 from '../images/presentation_images/no_dog.png'
import img3 from '../images/presentation_images/no_dog.png'
import img1 from '../images/welcome_images/HDPicture/1.jpeg'

function PresentationScreen() {
    return (
        <>
            <Container fluid className=" background">

                <Row className='description-container'>
                    <Col className='description'>
                        <h1 className='title'>Description</h1>
                        <br />
                        <p>This tastefully renovated family home boasts a breathtaking view of the sea. Enjoy the sea as far as the eye can see during your stay, in summer and winter it is a permanent show that awaits you. Ideal for a large family or a holiday with friends, you will be charmed by the charming seaside decoration of the house.</p>
                        <br />
                        <p>As you enter, you will be seduced by the brightness and luminosity of the house: high ceilings, whitened parquet flooring, large windows, contemporary decor... On the ground floor, the large 45m² living room includes a modern open kitchen, a dining room and a lounge area with fireplace. A TV lounge will welcome young and old for relaxing evenings. The imposing staircase leads to the 1st floor which serves 3 bedrooms with 1 double bed and a bathroom with Italian shower. The second floor will be a haven for children with its 2 bedrooms and shower room.</p>
                        <br />
                    </Col>
                    <Col lg={6} className='image-container'>
                        <img src={img1} alt='house' className='image'></img>
                    </Col>

                </Row>
                <Row className='main_informations'>
                    <Col className='fitcontent text1'>
                        <h1>Capacity</h1>
                        <h4>10 persons</h4>
                    </Col>
                    <Col className='fitcontent text1'>
                        <h1 >Bedrooms</h1>
                        <h4>5 Bedrooms</h4>
                    </Col>
                    <Col className='fitcontent text1'>
                        <h1> Surface</h1>
                        <h4>210 m²</h4>
                    </Col>
                    <Col className='fitcontent text1'>
                        <h1>Catégory</h1>
                        <h4>Single-family home</h4>
                    </Col>
                </Row>
                <Row className='fourth-row'>
                    <Col className='fitcontent'>
                        <AiOutlineWifi className='logo' />
                        <h4>Wifi</h4>

                    </Col>
                    <Col className='fitcontent text1'>
                        <img src={img2} className='logo'></img>
                        <h4> No pets </h4>

                    </Col>

                </Row>

                <Row>
                    <h1 className='title'>Facilities</h1>
                </Row>
                <Row className='equipement-container'>
                    <Col className='equipement1'>
                        <h5 id='title'>Interior</h5>

                        <div id='list-container'>
                            <ul id='list'>
                                <li>Fireplace</li>
                                <li>Washing machine</li>
                                <li>Dryer</li>
                                <li>Microwave</li>
                                <li>Fully equipped kitchen</li>

                            </ul>

                            <ul id='list'>
                                <li>Freezer</li>
                                <li>Dishwasher</li>
                                <li>Wood burning stove</li>
                                <li>TV</li>
                                <li>PS3</li>


                            </ul>
                        </div>
                    </Col>

                    <Col className='equipement1'>
                        <h5 id='title'>Outdoor</h5>

                        <div id='list-container'>
                            <ul id='list2'>
                                <li>Garden furniture</li>
                                <li>Barbecue</li>

                            </ul>


                            <ul id='list2'>
                                <li>Loungers</li>

                            </ul>
                        </div>
                    </Col>

                    <Col className='equipement1'>
                        <div className='subtitle'>
                            <h5 id='title'>Shops</h5>
                            <p id='commerces'> (within 700m)</p>
                        </div>
                        <div id='list-container'>
                            <ul id='list'>
                                <li>Géant Casino </li>
                                <li>Bakery </li>
                                <li>Pharmacy </li>


                            </ul>

                            <ul id='list'>
                                <li>Beach</li>
                                <li>Doctor</li>
                            </ul>
                        </div>
                    </Col>


                </Row>


            </Container>
        </>
    )
}

export default PresentationScreen;
