import React from 'react'
import { Container, Row, Carousel } from 'react-bootstrap'
import '../styles/GalleryStyle.css'
import Button from '../components/Button'
import NavBar from '../components/NavBar'
import images from '../images/imageOutside'
import Footer from './Footer'
import ReactPlayer from "react-player"



function GalleryAlentours() {

    const list_of_images = images.map(el => {
        return (
            <Carousel.Item className='item'><img className='img-fluid image1' src={process.env.PUBLIC_URL+el.src} srcSet={process.env.PUBLIC_URL+el.srcset} alt='picture' /></Carousel.Item>
        )
    });

    return (



        <Container fluid className='gallery-container'>
            <NavBar />

            <Row className='gallery-title'>
                <h1 className='title'>Les Alentours </h1>
            </Row>

            <Row className='container-carousel'>
                <Carousel className='carousel' slide={true} touch={true} fade={true} >
                    {list_of_images}
                </Carousel>
            </Row>
            <br />
            <h4>Autres photos</h4>
            <Row className='third-row'>
                <Button className="interieur RDC" link="/RDC" name='RDC' />
                <Button className="exterieur firstfloor" link="/firstFloor" name='1er Etage' />
                <Button className="environnement jardin" link="Jardin" name='Jardin' />
            </Row>

            <p id='title_video'>Venez survoler Plougasnou !</p>
            <ReactPlayer
                url="https://www.youtube.com/watch?v=QjOSSPBV6_8&ab_channel=SensationBretagne"
                id='video'
            />

            <p id='title_video'>Plongez dans l'ambiance de la baie de morlaix !</p>
            <ReactPlayer
                url="https://www.youtube.com/watch?v=gpJ1K2VdVno&ab_channel=DESTINATIONBAIEDEMORLAIXBRETAGNE"
                id='video2'
            />

            <Footer />
        </Container>
    )
}
export default GalleryAlentours;

