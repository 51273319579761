import React from 'react';
import '../styles/FooterStyle.css';
import { Row, Col, Container } from 'react-bootstrap'
import '../styles/PresentationStyle.css'
import Button from '../components/Button'



const Footer = () => {
    return (
        <>
            <Container fluid className="background-tarif">
                <Row >
                    <Col md={6} className='contacts'>
                        <h1>Contact </h1>
                        <h5>Téléphone : 07 67 37 42 75 </h5>
                        <h5>Mail : florence.pierronne@sfr.fr </h5>
                        <h5>Adresse : 16 impasse du Coran, Plougasnou, 29630 </h5>

                    </Col>

                    <Col className='reseaux'>
                        <div id='logo-container'>
                            <Button className="logo-button facebook" link="https://www.surf-report.com/meteo-surf/le-petit-minou-s1144.html" />
                            <Button className="logo-button instagram" link="https://www.instagram.com/la_terrasse_de_sophie/?igshid=1av3f8npf6enu" />
                            <Button className="logo-button youtube" link="https://www.youtube.com/channel/UCI1cdoDG6AUDwWNEFkTooWQ" />
                        </div>

                        <div id='logo-container'>
                            <a id='link' href="https://www.surf-report.com/meteo-surf/le-petit-minou-s1144.html"> Facebook</a>
                            <a id='link' href="https://www.instagram.com/la_terrasse_de_sophie/?igshid=1av3f8npf6enu"> Instagram</a>
                            <a id='link' href="https://www.youtube.com/channel/UCI1cdoDG6AUDwWNEFkTooWQ"> Youtube</a>
                        </div>
                    </Col>
                </Row>

            </Container>


        </>
    )
}


export default Footer;
