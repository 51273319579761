import React from 'react';
import { Link } from 'react-router-dom';

const style = {
    borderRadius: "40%",
    padding: "5px 0 5px 0",
    height: "100px",
    width: "200px",


}

function Button(props) {
    return (
        <div >
            <Link to={props.link}>
                <button className={props.className} >
                    {props.name}
                </button>
            </Link>

        </div>
    )
}

export default Button;

