import React from 'react'
import { Container, Row, Carousel } from 'react-bootstrap'
import '../styles/GalleryStyle.css'
import images from '../images/imageRDC'
import Button from '../components/Button'
import NavBar from '../components/NavBar'
import ReactPlayer from "react-player"
import Footer from './Footer'




function GalleryRDC() {


    const list_images = images.map((el) => {
        return (
            <Carousel.Item className='item'><img className='img-fluid image1' src={el.src} srcSet={el.srcset} sizes="(max-width: 640px) 640px, 100vw" alt='picture' /></Carousel.Item>
        )
    })
    return (

        <Container fluid className='gallery-container'>
            <NavBar />
            <Row className='gallery-title'>
                <h1 className='title'> Maison - Rez de Chaussée</h1>
            </Row>

            <Row className='container-carousel'>
                <Carousel className='carousel' slide={true} touch={true} fade={true} >
                    {list_images}
                </Carousel>
            </Row>
            <br />
            <h4>Autres photos</h4>

            <Row className='third-row'>
                <Button className="interieur firstfloor" link="/firstFloor" name='1er Etage' />
                <Button className="exterieur secondfloor" link="/secondFloor" name='2ème Etage' />
                <Button className="environnement jardin" link="/jardin" name='Jardin' />

            </Row>

            <Footer />
        </Container>
    )
}
export default GalleryRDC;

