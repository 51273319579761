import React from 'react';
import WelcomeScreen from './WelcomeScreen';
import PresentationScreen from './PresentationScreen';
import Navbar from '../components/NavBar';
import Tarif from './TarifScreen';
import Footer from './Footer';
import Avis from './AvisScreen';
import GalleryScreen from './GalleryScreen';


function Home() {
    return (
        <div>
            <Navbar />
            <div id="welcome">
                <WelcomeScreen />
            </div>
            <div id="description">
                <PresentationScreen />
            </div>
            <div id="gallery">
                <GalleryScreen />
            </div>
            <div id="tarif">
                <Tarif />
            </div>
            <div id="avis">
                <Avis />
            </div>
            <div id="footer">
                <Footer />
            </div>

        </div>
    )
}

export default Home;
