import React from 'react'
import { Container, Row, Carousel } from 'react-bootstrap'
import '../styles/GalleryStyle.css'
import images from '../images/imageFirstFloor'
import Button from '../components/Button'
import NavBar from '../components/NavBar'
import ReactPlayer from "react-player"
import Footer from './Footer'



function GalleryFirstFloor() {


    const list_images = images.map((el) => {
        return (
            <Carousel.Item className='item'><img className='img-fluid image1' src={el.src} srcSet={el.srcset} alt='picture' /></Carousel.Item>
        )
    })
    return (

        <Container fluid className='gallery-container'>
            <NavBar />
            <Row className='gallery-title'>
                <h1 className='title'> Maison - 1er étage</h1>
            </Row>

            <Row className='container-carousel'>
                <Carousel className='carousel' slide={true} touch={true} fade={true} >
                    {list_images}
                </Carousel>
            </Row>
            <br />
            <h4>Autres photos</h4>



            <Row className='third-row'>
                <Button  className="interieur RDC" link="/RDC" name='Rez de chaussée' />
                <Button id="secondfloor" className="secondfloor exterieur" link="/secondFloor" name='2ème Etage' />
                <Button id="jardin" className="jardin environnement" link="/jardin" name='Jardin' />

            </Row>

            <p id='title_video'>Plongez dans l'ambiance de la baie de morlaix !</p>
            <ReactPlayer
                url="https://www.youtube.com/watch?v=gpJ1K2VdVno&ab_channel=DESTINATIONBAIEDEMORLAIXBRETAGNE"
                id='video'
            />

            <Footer />
        </Container>
    )
}
export default GalleryFirstFloor;

