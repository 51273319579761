const images = [{
    key:1,
    src:'../images/galleryMaison/1erEtage/HDPictures/etage1_1.jpeg',
    srcset:"../images/galleryMaison/640x320/etage1_1.jpeg 640px, ../images/galleryMaison/1erEtage/HDPictures/etage1_1.jpeg 1024px"
},{
    key:2,
    src:'../images/galleryMaison/1erEtage/HDPictures/etage1_2.jpeg',
    srcset:"../images/galleryMaison/640x320/etage1_2.jpeg 640px, ../images/galleryMaison/1erEtage/HDPictures/etage1_2.jpeg 1024px"
},{
    key:3,
    src:'../images/galleryMaison/1erEtage/HDPictures/etage1_3.jpeg',
    srcset:"../images/galleryMaison/640x320/etage1_3.jpeg 640px, ../images/galleryMaison/1erEtage/HDPictures/etage1_3.jpeg 1024px"
},{
    key:4,
    src:'../images/galleryMaison/1erEtage/HDPictures/etage1_4.jpeg',
    srcset:"../images/galleryMaison/640x320/etage1_4.jpeg 640px, ../images/galleryMaison/1erEtage/HDPictures/etage1_4.jpeg 1024px"
},{
    key:5,
    src:'../images/galleryMaison/1erEtage/HDPictures/etage1_5.jpeg',
    srcset:"../images/galleryMaison/640x320/etage1_5.jpeg 640px, ../images/galleryMaison/1erEtage/HDPictures/etage1_5.jpeg 1024px"
},{
    key:6,
    src:'../images/galleryMaison/1erEtage/HDPictures/etage1_6.jpeg',
    srcset:"../images/galleryMaison/640x320/etage1_6.jpeg 640px, ../images/galleryMaison/1erEtage/HDPictures/etage1_6.jpeg 1024px"
},{
    key:7,
    src:'../images/galleryMaison/1erEtage/HDPictures/etage1_7.jpeg',
    srcset:"../images/galleryMaison/640x320/etage1_7.jpeg 640px, ../images/galleryMaison/1erEtage/HDPictures/etage1_7.jpeg 1024px"
}]

export default images