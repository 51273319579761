import React from 'react'
import { Container, Row, Carousel } from 'react-bootstrap'
import '../styles/Videos.css'
import images from '../images/imageFirstFloor'
import Button from '../components/Button'
import NavBar from '../components/NavBar'
import ReactPlayer from "react-player"
import Footer from './FooterEN'



function Videos() {
    return (

        <Container fluid className='gallery-container'>
            <NavBar />
            <Row className='gallery-title'>
                <h1 className='title'> Vidéos</h1>
            </Row>

            <br /><br />
            <p id='video_text'>If you are still not convinced by the photos, feel free to take a walk through the house with the following videos:</p>
            <br /><br /><br />

            <Row className='video_page'>
                <h1 className='subtitle'> The surroundings</h1>
                <p id='title_video'>Come and fly over Plougasnou!</p>
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=QjOSSPBV6_8&ab_channel=SensationBretagne"
                    id='video'
                />

                <p id='title_video'>Immerse yourself in the atmosphere of the Bay of Morlaix!</p>
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=gpJ1K2VdVno&ab_channel=DESTINATIONBAIEDEMORLAIXBRETAGNE"
                    id='video2'
                />
                <p id='title_video'>*******</p>
            </Row>


            <Row className='video_page2'>
                <h1 className='subtitle'> Garden</h1>
                <ReactPlayer
                    url="hhttps://www.youtube.com/watch?v=r-y2630RaV8&ab_channel=LaTerrassedeSophie"
                    id='video'
                />

                <p id='title_video'>*******</p>
            </Row>




            <Row className='video_page'>
                <h1 className='subtitle'> The House</h1>
                <p id='title_video'>Ground Floor</p>
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=KBtcPXEEKz4&ab_channel=LaTerrassedeSophie"
                    id='video'
                />

                <br />

                <p id='title_video'>First Floor</p>
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=SWqEVMi67ek&t=14s&ab_channel=LaTerrassedeSophie"
                    id='video2'
                />

                <p id='title_video'>Second Floor</p>
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=MQjg9B3mmEs&ab_channel=LaTerrassedeSophie"
                    id='video'
                />

                <br />
                <p id='title_video'>*******</p>
            </Row>






            <Footer />
        </Container>
    )
}
export default Videos;

