import React from 'react'
import '../styles/AvisStyle.css'
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";


function AvisScreen() {
    return (
        <>
            <div className='avis-container'>
                <h1 className='title'>Les Avis</h1>

                <div className='avis'>
                    <p id='text-avis'><FaQuoteLeft size='20' /> Très bonne expérience chez Florence et David. Belle maison récemment rénovée, localisation parfaite et vue exceptionnelle sur la mer. Je recommande vivement <FaQuoteRight size='20' /></p>
                    <p id='signature'>Sylvain, Abritel</p>
                </div>

                <div className='avis'>
                    <p id='text-avis'><FaQuoteLeft size='20' /> Simplement parfait en tout point. Avec une mention spéciale pour la vue, le calme et l'équipement de la maison. Un peu plus de mobilier de jardin aurait été le bienvenu <FaQuoteRight size='20' /></p>
                    <p id='signature'>Vincent, Abritel</p>
                </div>

                <div className='avis'>
                    <p id='text-avis'><FaQuoteLeft size='20' /> Emplacement magnifique - vue splendide - quartier calme proche du centre bourg et de la plage.
                    Maison spacieuse et confortable, très bien équipée et chaleureuse. Toutes les pièces quasiment ont vue sur la mer.
                    Jardin et terrasse vraiment chouette. On se sent bien dans cette maison idéale pour accueillir la famille.<FaQuoteRight size='20' /></p>
                    <p id='signature'>Caroline, Abritel</p>
                </div>



            </div>
        </>
    )
}
export default AvisScreen;
