const images = [{
    key:1,
    src:'/images/jardin/HDPicture/1.jpeg',
    srcset:"/images/jardin/640x360/1.jpeg 640px, /images/jardin/HDPicture/1.jpeg 1024px"
},{
    key:2,
    src:'/images/jardin/HDPicture/2.jpeg',
    srcset:"/images/jardin/640x360/2.jpeg 640px, /images/jardin/HDPicture/2.jpeg 1024px"
},{
    key:3,
    src:'/images/jardin/HDPicture/3.jpeg',
    srcset:"/images/jardin/640x360/3.jpeg 640px, /images/jardin/HDPicture/3.jpeg 1024px"
},{
    key:4,
    src:'/images/jardin/HDPicture/4.jpeg',
    srcset:"/images/jardin/640x360/4.jpeg 640px, /images/jardin/HDPicture/4.jpeg 1024px"
},{
    key:5,
    src:'/images/jardin/HDPicture/4.jpeg',
    srcset:"/images/jardin/640x360/4.jpeg 640px, /images/jardin/HDPicture/4.jpeg 1024px"
},{
    key:6,
    src:'/images/jardin/HDPicture/5.jpeg',
    srcset:"/images/jardin/640x360/5.jpeg 640px, /images/jardin/HDPicture/5.jpeg 1024px"
},{
    key:7,
    src:'/images/jardin/HDPicture/10.jpeg',
    srcset:"/images/jardin/640x360/10.jpeg 640px, /images/jardin/HDPicture/10.jpeg 1024px"
},{
    key:8,
    src:'/images/jardin/HDPicture/11.jpeg',
    srcset:"/images/jardin/640x360/11.jpeg 640px, /images/jardin/HDPicture/11.jpeg 1024px"
},{
    key:9,
    src:'/images/jardin/HDPicture/12.jpeg',
    srcset:"/images/jardin/640x360/12.jpeg 640px, /images/jardin/HDPicture/12.jpeg 1024px"
},{
    key:10,
    src:'/images/jardin/HDPicture/13.jpeg',
    srcset:"/images/jardin/640x360/13.jpeg 640px, /images/jardin/HDPicture/13.jpeg 1024px"
},]

export default images