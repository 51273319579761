const images = [{
    key:1,
    src:'/images/outside/HDPicture/1.jpeg',
    srcset:"/images/outside/640x320/1.jpeg 640px, /images/outside/HDPicture/1.jpeg 1024px"
},{
    key:2,
    src:'/images/outside/HDPicture/2.jpeg',
    srcset:"/images/outside/640x320/2.jpeg 640px, /images/outside/HDPicture/2.jpeg 1024px"
},{
    key:3,
    src:'/images/outside/HDPicture/3.jpeg',
    srcset:"/images/outside/640x320/3.jpeg 640px, /images/outside/HDPicture/3.jpeg 1024px"
},{
    key:4,
    src:'/images/outside/HDPicture/4.jpeg',
    srcset:"/images/outside/640x320/4.jpeg 640px, /images/outside/HDPicture/4.jpeg 1024px"
},{
    key:5,
    src:'/images/outside/HDPicture/5.jpeg',
    srcset:"/images/outside/640x320/5.jpeg 640px, /images/outside/HDPicture/5.jpeg 1024px"
},{
    key:6,
    src:'/images/outside/HDPicture/6.jpeg',
    srcset:"/images/outside/640x320/6.jpeg 640px, /images/outside/HDPicture/6.jpeg 1024px"
},{
    key:7,
    src:'/images/outside/HDPicture/8.jpeg',
    srcset:"/images/outside/640x320/8.jpeg 640px, /images/outside/HDPicture/8.jpeg 1024px"
},{
    key:8,
    src:'/images/outside/HDPicture/9.jpeg',
    srcset:"/images/outside/640x320/9.jpeg 640px, /images/outside/HDPicture/9.jpeg 1024px"
},{
    key:9,
    src:'/images/outside/HDPicture/10.jpeg',
    srcset:"/images/outside/640x320/10.jpeg 640px, /images/outside/HDPicture/10.jpeg  1024px"
},]

export default images;