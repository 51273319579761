const images = [{
    key:1,
    src:'../images/galleryMaison/2emeEtage/HDPicture/etage2_1.jpeg',
    srcset:"../images/galleryMaison/2emeEtage/640x360/etage2_1.jpeg 640px, ../images/galleryMaison/2emeEtage/HDPicture/etage2_1.jpeg 1024px"
},{
    key:2,
    src:'../images/galleryMaison/2emeEtage/HDPicture/etage2_2.jpeg',
    srcset:"../images/galleryMaison/2emeEtage/640x360/etage2_2.jpeg 640px, ../images/galleryMaison/2emeEtage/HDPicture/etage2_2.jpeg 1024px"
},{
    key:3,
    src:'../images/galleryMaison/2emeEtage/HDPicture/etage2_4.jpeg',
    srcset:"../images/galleryMaison/2emeEtage/640x360/etage2_4.jpeg 640px, ../images/galleryMaison/2emeEtage/HDPicture/etage2_4.jpeg 1024px"
},{
    key:4,
    src:'../images/galleryMaison/2emeEtage/HDPicture/etage2_6.jpeg',
    srcset:"../images/galleryMaison/2emeEtage/640x360/etage2_6.jpeg 640px, ../images/galleryMaison/2emeEtage/HDPicture/etage2_6.jpeg 1024px"
},{
    key:5,
    src:'../images/galleryMaison/2emeEtage/HDPicture/etage2_7.jpeg',
    srcset:"../images/galleryMaison/2emeEtage/640x360/etage2_7.jpeg 640px, ../images/galleryMaison/2emeEtage/HDPicture/etage2_7.jpeg 1024px"
}]

export default images