import React, { useState } from 'react';
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../styles/Navbar.css';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Button from '../components/Button'
import { HashLink as Link } from 'react-router-hash-link'



const propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};


function Navbar({ location }) {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const scrollWidthOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }
    return (
        <>
            <nav className="navbar">
                <div className="navbar-container">
                    <Link activeClass="active" scroll={(el) => { scrollWidthOffset(el) }} className="navbar-logo" spy={true} to="/#welcome" smooth={true} >
                        La Terrasse de Sophie
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        <FontAwesomeIcon icon={click ? faTimes : faBars} />
                    </div>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className="nav-item">
                            <Link scroll={(el) => { scrollWidthOffset(el) }} to="/#description" className="nav-links" smooth={true} spy={true} onClick={closeMobileMenu}  >
                                Description
                            </Link>
                        </li>
                        <li>
                            <DropdownButton
                                id={`dropdown`}
                                drop={'down'}
                                title={`Photos`}
                            >
                                <Dropdown.Item eventKey="1" onClick={closeMobileMenu} id='NavDropdown-item'> <Link to="/#gallery" scroll={(el) => { scrollWidthOffset(el) }} id='link-item' >Galerie </Link></Dropdown.Item>
                                <Dropdown.Item eventKey="2" onClick={closeMobileMenu} id='NavDropdown-item'><Link to="/RDC" id='link-item'> Maison</Link></Dropdown.Item>
                                <Dropdown.Item eventKey="3" onClick={closeMobileMenu} id='NavDropdown-item'><Link to="/jardin" id='link-item'>Jardin</Link></Dropdown.Item>
                                <Dropdown.Item eventKey="3" onClick={closeMobileMenu} id='NavDropdown-item'><Link to="/environment" id='link-item'> Les Alentours</Link></Dropdown.Item>
                                <Dropdown.Item eventKey="3" onClick={closeMobileMenu} id='NavDropdown-item'><Link to="/videos" id='link-item'> Vidéos</Link></Dropdown.Item>

                            </DropdownButton>
                        </li>
                        <li className="nav-item">
                            <Link activeClass="active" scroll={(el) => { scrollWidthOffset(el) }} className="nav-links" spy={true} to="/#tarif" smooth onClick={closeMobileMenu} >
                                Tarif
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-links" activeClass="active" spy={true} to="/#footer" smooth={true} offset={-80} onClick={closeMobileMenu}>
                                Nous contacter
                            </Link>
                        </li>
                        <li className="bloc-langues">
                            <Button className="french" link="french" />
                            <Button className="english" link="english" />

                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
}

Navbar.propTypes = propTypes;

export default Navbar;