const images = [{
    key:1,
    src:'../images/galleryMaison/RDC/HDPicture/rdc_1.jpeg',
    srcset:"../images/galleryMaison/RDC/640x320/rdc_1.jpeg 640px, ../images/galleryMaison/RDC/HDPicture/rdc_1.jpeg 1024px"
},{
    key:2,
    src:'../images/galleryMaison/RDC/HDPicture/rdc_2.jpeg',
    srcset:"../images/galleryMaison/RDC/640x320/rdc_2.jpeg 640px, ../images/galleryMaison/RDC/HDPicture/rdc_2.jpeg 1024px"
},{
    key:3,
    src:'../images/galleryMaison/RDC/HDPicture/rdc_3.jpeg',
    srcset:"../images/galleryMaison/RDC/640x320/rdc_3.jpeg 640px, ../images/galleryMaison/RDC/HDPicture/rdc_3.jpeg 1024px"
},{
    key:4,
    src:'../images/galleryMaison/RDC/HDPicture/rdc_4.jpeg',
    srcset:"../images/galleryMaison/RDC/640x320/rdc_4.jpeg 640px, ../images/galleryMaison/RDC/HDPicture/rdc_4.jpeg 1024px"
},{
    key:5,
    src:'../images/galleryMaison/RDC/HDPicture/rdc_5.jpeg',
    srcset:"../images/galleryMaison/RDC/640x320/rdc_5.jpeg 640px, ../images/galleryMaison/RDC/HDPicture/rdc_5.jpeg 1024px"
},{
    key:6,
    src:'../images/galleryMaison/RDC/HDPicture/rdc_6.jpeg',
    srcset:"../images/galleryMaison/RDC/640x320/rdc_6.jpeg 640px, ../images/galleryMaison/RDC/HDPicture/rdc_6.jpeg 1024px"
},{
    key:7,
    src:'../images/galleryMaison/RDC/HDPicture/rdc_8.jpeg',
    srcset:"../images/galleryMaison/RDC/640x320/rdc_8.jpeg 640px, ../images/galleryMaison/RDC/HDPicture/rdc_8.jpeg 1024px"
},{
    key:8,
    src:'../images/galleryMaison/RDC/HDPicture/rdc_9.jpeg',
    srcset:"../images/galleryMaison/RDC/640x320/rdc_9.jpeg 640px, ../images/galleryMaison/RDC/HDPicture/rdc_9.jpeg 1024px"
},{
    key:9,
    src:'../images/galleryMaison/RDC/HDPicture/rdc_10.jpeg',
    srcset:"../images/galleryMaison/RDC/640x320/rdc_10.jpeg 640px, ../images/galleryMaison/RDC/HDPicture/rdc_10.jpeg  1024px"
},]

export default images;