import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import '../styles/PresentationStyle.css'
import { GiFamilyHouse } from 'react-icons/gi'
import { FaRestroom } from 'react-icons/fa'
import { AiOutlineWifi } from 'react-icons/ai'
import img2 from '../images/presentation_images/no_dog.png'
import img1 from '../images/welcome_images/HDPicture/1.jpeg'

function PresentationScreen() {
    return (
        <>
            <Container fluid className=" background">
                <Row className='title-container'>

                </Row>
                <Row className='description-container'>
                    <Col className='description'>
                        <h1 className='title'>Description</h1>
                        <br />
                        <p>Cette demeure familiale rénovée avec goût bénéficie d'une vue imprenable sur la mer. Profitez pendant votre séjour de la mer à perte de vue, en été comme en hiver c'est un spectacle permanent qui vous attend. Idéale pour une grande famille ou des vacances entre amis, vous serez charmé par la décoration bord de mer de charme de la maison.</p>
                        <br />
                        <p>En entrant vous serez séduit par la clarté et la luminosité de la maison : grande hauteur sous plafond, parquet blanchi, larges fenêtres, déco contemporaine... Au rez de chaussée la grande pièce de vie de 45m² comprend une cuisine ouverte moderne, une salle à manger et un espace salon avec cheminée. Un salon TV accueillera petits et grands pour des soirées détente. L'imposant escalier mène au 1er étage qui dessert 3 chambres de 1 lit 2 personnes et une salle d'eau avec douche italienne. Le second étage sera le repère des enfants avec ses 2 chambres et sa salle d'eau.</p>
                        <br />
                    </Col>
                    <Col lg={6} className='image-container'>
                        <img src={img1} alt='house' className='image'></img>
                    </Col>

                </Row>
                <Row className='main_informations'>
                    <Col className='fitcontent text1'>
                        <h1>Capacité</h1>
                        <h4>10 Personnes</h4>
                    </Col>
                    <Col className='fitcontent text1'>
                        <h1 >Chambres</h1>
                        <h4>5 Chambres</h4>
                    </Col>
                    <Col className='fitcontent text1'>
                        <h1> Surface</h1>
                        <h4>210 m²</h4>
                    </Col>
                    <Col className='fitcontent text1'>
                        <h1>Catégorie</h1>
                        <h4>Maison individuelle</h4>
                    </Col>
                </Row>

                <Row className='fourth-row'>
                    <Col className='fitcontent'>
                        <AiOutlineWifi className='logo' />
                        <h4>Wifi</h4>

                    </Col>
                    <Col className='fitcontent text1'>
                        <img src={img2} className='logo'></img>
                        <h4> Pas d'animaux </h4>

                    </Col>

                </Row>
                <br /><br />

                <Row>
                    <h1 className='title'>Équipements</h1>
                </Row>
                <Row className='equipement-container'>
                    <Col className='equipement1'>
                        <h5 id='title'>Intérieur</h5>

                        <div id='list-container'>
                            <ul id='list'>
                                <li>Cheminée</li>
                                <li>Lave-linge</li>
                                <li>Sèche linge</li>
                                <li>Micro-ondes</li>
                                <li>Cuisine tout équipée</li>

                            </ul>

                            <ul id='list'>
                                <li>Congélateur</li>
                                <li>Lave-vaisselle</li>
                                <li>Poêle à bois</li>
                                <li>Télévision</li>
                                <li>PS3</li>
                                <li>Équipement bébé</li>

                            </ul>
                        </div>
                    </Col>

                    <Col className='equipement1'>
                        <h5 id='title'>Extérieur</h5>

                        <div id='list-container'>
                            <ul id='list2'>
                                <li>Salon de Jardin</li>
                                <li>Barbecue</li>

                            </ul>


                            <ul id='list2'>
                                <li>Transats</li>

                            </ul>
                        </div>
                    </Col>

                    <Col className='equipement1'>
                        <div className='subtitle'>
                            <h5 id='title'>Commerces </h5>
                            <p id='commerces'> (à moins de 700m)</p>
                        </div>
                        <div id='list-container'>
                            <ul id='list'>
                                <li>Géant Casino </li>
                                <li>Boulangerie </li>
                                <li>Pharmacie </li>


                            </ul>

                            <ul id='list'>
                                <li>Plage</li>
                                <li>Medecin</li>
                            </ul>
                        </div>
                    </Col>


                </Row>

            </Container>
        </>
    )
}

export default PresentationScreen;
