import React from 'react';
import { Carousel } from 'react-bootstrap';
import '../styles/WelcomeStyle.css';
import { useMediaQuery } from 'react-responsive'
import img1 from '../images/welcome_images/HDPicture/1.jpeg';
import img2 from '../images/welcome_images/HDPicture/2.jpeg';
import img3 from '../images/welcome_images/HDPicture/3.jpeg';
import img4 from '../images/welcome_images/HDPicture/4.jpeg';
import portrait1 from '../images/welcome_images/HDPicture/portrait1.jpg'
import portrait2 from '../images/welcome_images/HDPicture/portrait2.jpeg'
import portrait3 from '../images/welcome_images/HDPicture/portrait3.jpg'
import portrait4 from '../images/welcome_images/HDPicture/portrait4.jpg'


function WelcomeScreen() {

  const isMobileDevice = useMediaQuery({ maxWidth: 1000 })

  console.log(isMobileDevice)
  return (

    <Carousel className='carousel-container'>
      <Carousel.Item className='carousel-item'>
        {isMobileDevice ? <img
          className="image-carousel"
          src={portrait1}
          alt="First slide" /> : <img
          className="image-carousel"
          src={img1}
          alt="First slide" />}

        <Carousel.Caption className='carousel-caption'>
          <h1>Bienvenue à la Terrasse de Sophie ! </h1><h3>Cette superbe maison située dans le Nord Finistère avec une vue incroyable sur la mer, ne vous décevra pas !</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        {isMobileDevice ? <img
          className="image-carousel"
          src={portrait2}
          alt="First slide" /> : <img
          className="image-carousel"
          src={img2}
          alt="First slide" />}

        <Carousel.Caption className='carousel-caption'>
          <h3>Cette demeure authentique de 5 chambres pour 10 personnes vous séduira par son charme, son confort et sa vue imprenable sur la mer. Vous vivrez au rythme des marées et profiterez du calme absolu</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        {isMobileDevice ? <img
          className="image-carousel"
          src={portrait3}
          alt="First slide" /> : <img
          className="image-carousel"
          src={img3}
          alt="First slide" />}
        <Carousel.Caption className='carousel-caption'>
          <h3>Que ce soit pour le temps d’un week-end ou pour plusieurs semaines, à l’occasion d’une réunion de famille, de retrouvailles entre amis, vous saurez profiter de ces bons moments au calme, face à la mer...</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        {isMobileDevice ? <img
          className="image-carousel"
          src={portrait4}
          alt="First slide" /> : <img
          className="image-carousel"
          src={img4}
          alt="First slide" />}

        <Carousel.Caption className='carousel-caption'>
          <h3>Qu'attendez-vous pour venir nous rendre visite à Plougasnou ? <br /><br /> Nous serons ravis de vous accueillir et vous faire découvir les merveilles de la Bretagne !</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

  )
}

export default WelcomeScreen;
