import React from 'react'
import '../styles/AvisStyle.css'
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";


function AvisScreenEN() {
    return (
        <>
            <div className='avis-container'>
                <h1 className='title'>Opinions</h1>

                <div className='avis'>
                    <p id='text-avis'><FaQuoteLeft size='20' /> Very good experience with Florence and David. Beautiful house recently renovated, perfect location and exceptional view on the sea. I highly recommend  <FaQuoteRight size='20' /></p>
                    <p id='signature'>Sylvain, Abritel</p>
                </div>

                <div className='avis'>
                    <p id='text-avis'><FaQuoteLeft size='20' /> Simply perfect in every way. With a special mention for the view, the quietness and the equipment of the house. A little more garden furniture would have been welcome <FaQuoteRight size='20' /></p>
                    <p id='signature'>Vincent, Abritel</p>
                </div>

                <div className='avis'>
                    <p id='text-avis'><FaQuoteLeft size='20' /> Beautiful location - splendid view - quiet area close to the town centre and the beach.
                    Spacious and comfortable house, very well equipped and warm. Almost every room has a view of the sea.
                    The garden and terrace are really nice. We feel good in this house, ideal to welcome the family.<FaQuoteRight size='20' /></p>
                    <p id='signature'>Caroline, Abritel</p>
                </div>


            </div>
        </>
    )
}
export default AvisScreenEN;